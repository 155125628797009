export const sizes = [
  "ONE",
  "XS",
  "S",
  "SP",
  "M",
  "MP",
  "L",
  "XL",
  "2XL",
  "3XL",
  "4XL",
  "5XL",
  "C",
  "14K",
  "18K"
];
