import { Box, Button, Flex, Input } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useMutation } from "react-query";
import formatMoney from "../../utils/formatMoney";

interface Props {
  price: number;
  id: string;
  refetch: any;
}
const PriceItem: React.FC<Props> = ({ price, id, refetch }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [newPrice, setNewPrice] = useState<string | number>(price);

  const { mutateAsync, isLoading } = useMutation(() =>
    axios.post(`${process.env.REACT_APP_APIURL}/eX/updatePrice/${id}`, {
      data: { price: Number(newPrice) }
    })
  );
  const handleSave = async () => {
    await mutateAsync();
    await refetch();
    return setIsUpdate(false);
  };
  return (
    <Box border="1px solid #ccc" p={2} mb={1}>
      <Flex justifyContent={"space-between"}>
        <Box>{formatMoney(price, "USD")}</Box>
        <Button
          variant="green"
          size="xs"
          onClick={() => setIsUpdate(!isUpdate)}
        >
          <FaEdit />
        </Button>
      </Flex>
      {isUpdate && (
        <Box>
          <Input
            bgColor={"white"}
            onChange={(e) => setNewPrice(e.target.value)}
            value={newPrice}
          />
          <Box>
            <Button
              width={"100%"}
              mt={1}
              colorScheme={"green"}
              size="sm"
              onClick={() => handleSave()}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PriceItem;
