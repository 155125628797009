import { Box, Flex } from "@chakra-ui/react";

const OrderStatus = ({ data }: { data: any }) => {
  return (
    <Flex
      border="1px solid #edf2f7"
      justifyContent={"center"}
      alignItems="center"
      p={8}
    >
      <Box as="span" fontWeight="bold" fontSize={22}>
        {data.data.orderStatus}
      </Box>
    </Flex>
  );
};

export default OrderStatus;
