import { Box, Button, Flex, Select } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useMutation } from "react-query";
import { usePropertyStore } from "../../store";

interface Props {
  material: string;
  color: string;
  id: string;
  refetch: any;
}
const MaterialItem: React.FC<Props> = ({ material, color, id, refetch }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [newColor, setNewColor] = useState("");
  const { colors } = usePropertyStore();

  const { mutateAsync, isLoading } = useMutation(() =>
    axios.post(
      `${process.env.REACT_APP_APIURL}/eX/changeMaterial/${id}/${material}/${color}/${newColor}`,
      {
        data: {}
      }
    )
  );
  const handleSave = async () => {
    await mutateAsync();
    await refetch();
    return setIsUpdate(false);
  };
  return (
    <Box border="1px solid #ccc" p={2} mb={1}>
      <Flex justifyContent={"space-between"}>
        <Box>
          {material}:{color}
        </Box>
        <Button
          variant="green"
          size="xs"
          onClick={() => setIsUpdate(!isUpdate)}
        >
          <FaEdit />
        </Button>
      </Flex>
      {isUpdate && colors && (
        <Box>
          <Select
            name="color"
            bgColor={"white"}
            value={newColor}
            onChange={(e) => setNewColor(e.target.value)}
            placeholder="Select a color"
          >
            {colors.map((c) => {
              return (
                <option key={c.title} value={c.title}>
                  {c.title}
                </option>
              );
            })}
          </Select>
          <Box>
            <Button
              width={"100%"}
              mt={1}
              colorScheme={"green"}
              size="sm"
              onClick={() => handleSave()}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MaterialItem;
