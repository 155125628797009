import { create } from "zustand";

interface BearState {
  colors: any[];
  setColors: (colors: any[]) => void;
  materials: any[];
  setMaterials: (materials: any[]) => void;
}

export const usePropertyStore = create<BearState>()((set) => ({
  colors: [],
  materials: [],
  setColors: (colors) => set((state) => ({ colors })),
  setMaterials: (materials) => set((state) => ({ materials }))
}));
