import React, { useEffect } from "react";
import { Box, Flex, Container } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import axios from "axios";
import { usePropertyStore } from "../store";

export const Layout: React.FC = ({ children }) => {
  const { setColors, setMaterials } = usePropertyStore();

  useEffect(() => {
    axios({
      method: "GET",
      url: "https://juju-brain.cr.interaktifis.cloud/color/list"
    }).then((r) => {
      console.log(r.data);
      setColors(r.data.colors);
    });

    axios({
      method: "GET",
      url: "https://juju-brain.cr.interaktifis.cloud/material/list"
    }).then((r) => {
      console.log(r.data);
      setMaterials(r.data.materials);
    });
  }, [setColors, setMaterials]);

  return (
    <Box>
      <Box bg="#862337">
        <Container maxW="container.xl">
          <Flex
            color="white"
            justifyContent="space-between"
            alignItems="center"
            p={4}
          >
            <Box bgColor={"white"} p={2}>
              <Link to="/dashboard">
                <img
                  src="https://images.jujumood.com/logo.png?format=webp&width=35&height=50"
                  alt="Juju Logo"
                />
              </Link>
            </Box>
            <Flex>
              <Box mr={4}>
                <Link to="/orders">Orders</Link>
              </Box>
              <Box>
                <Link to="/dashboard">Stores</Link>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Box>
      <hr />
      <Box>{children}</Box>
    </Box>
  );
};
