import { Box, Button, Flex, Select } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useMutation } from "react-query";
import { sizes } from "../../utils/sizes";

interface Props {
  size: string;
  id: string;
  refetch: any;
}
const SizeItem: React.FC<Props> = ({ size, id, refetch }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [newSize, setNewSize] = useState("");

  const { mutateAsync, isLoading } = useMutation(() =>
    axios.post(
      `${process.env.REACT_APP_APIURL}/eX/changeSize/${id}/${newSize}`,
      {
        data: {}
      }
    )
  );
  const handleSave = async () => {
    await mutateAsync();
    await refetch();
    return setIsUpdate(false);
  };
  return (
    <Box border="1px solid #ccc" p={2} mb={1}>
      <Flex justifyContent={"space-between"}>
        <Box>{size}</Box>
        <Button
          variant="green"
          size="xs"
          onClick={() => setIsUpdate(!isUpdate)}
        >
          <FaEdit />
        </Button>
      </Flex>
      {isUpdate && (
        <Box>
          <Select
            name="Size"
            bgColor={"white"}
            value={newSize}
            onChange={(e) => setNewSize(e.target.value)}
            placeholder="Select a size"
          >
            {sizes.map((c) => {
              return <option value={c}>{c}</option>;
            })}
          </Select>
          <Box>
            <Button
              width={"100%"}
              mt={1}
              colorScheme={"green"}
              size="sm"
              onClick={() => handleSave()}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SizeItem;
