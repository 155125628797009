import { Layout } from "./../Layout";
import {
  Box,
  Container,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import formatMoney from "../../utils/formatMoney";
import { usePropertyStore } from "../../store";
import dayjs from "dayjs";
import { useEffect, useLayoutEffect, useState } from "react";

const OrderDetail = () => {
  const { colors, materials, setMaterials, setColors } = usePropertyStore();
  const [print, setPrint] = useState(true);

  useEffect(() => {
    axios({
      method: "GET",
      url: "https://juju-brain.cr.interaktifis.cloud/color/list"
    }).then((r) => {
      setColors(r.data.colors);
    });

    axios({
      method: "GET",
      url: "https://juju-brain.cr.interaktifis.cloud/material/list"
    }).then((r) => {
      console.log(r.data);
      setMaterials(r.data.materials);
    });
  }, [setColors, setMaterials]);

  const { id } = useParams();

  const { isLoading, data } = useQuery(
    "query-order-details",
    async () => {
      const url = `${process.env.REACT_APP_APIURL}/eX/order/${id}`;
      return await axios({
        method: "GET",
        url
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  useEffect(() => {
    if (!isLoading && print) {
      setTimeout(() => {
        window.print();
      }, 1000);
      window.addEventListener("afterprint", (event) => {
        setPrint(() => false);
      });
    }
  }, [isLoading, print]);

  if (isLoading) {
    return (
      <Box
        position={"fixed"}
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        <Spinner size={"lg"} />
      </Box>
    );
  }
  if (data) {
    return (
      <Container maxW="container.xl" marginTop={4}>
        <Box fontSize={22} fontWeight="bold" textAlign={"center"}>
          INVOICE
        </Box>
        <Flex mb={2} textAlign="center" justifyContent={"center"}>
          <img
            src="https://images.jujumood.com/logo.png?format=png&trim=10&height=60"
            alt="Juju"
            title="JUJU"
          />
        </Flex>
        <Flex justifyContent={"space-between"} mb={8}>
          <Box>
            <Box fontSize={20} fontWeight="bold">
              JUJU TAKI TASARIM SAN. TİC. ve LTD.ŞTİ
            </Box>
            <Box>
              Ortabayır Mah. Talatpaşa Cad.
              <br /> No:21 Kat:2 Dükkan 201
              <br />
              34413 Kağıthane <br /> İstanbul Türkiye
            </Box>
          </Box>

          <Box textAlign={"right"}>
            <Box fontSize={20} fontWeight="bold">
              {data.data.store.title}
            </Box>
            {data.data.store.address && (
              <Box mb={4}>
                {data.data.store.address} / {data.data.store.country}
              </Box>
            )}
            <Box>{data.data.store.phoneNumber}</Box>
            <Box mb={4}>{data.data.store.email}</Box>
            <Box>
              Date Order: <br />
              {dayjs(data.data.createdAt).format("DD MMMM YYYY")}{" "}
              {dayjs(data.data.createdAt).format("HH:mm")}
            </Box>
          </Box>
        </Flex>
        {colors && materials && (
          <Table variant={"striped"} size="lg">
            <Thead>
              <Tr>
                <Th>Photo</Th>
                <Th>Title</Th>
                <Th>Barcode & SKU</Th>
                <Th>Materials</Th>
                <Th textAlign={"center"}>Size</Th>
                <Th textAlign={"center"}>Qty</Th>
                <Th textAlign={"right"}>Price</Th>
                <Th textAlign={"right"}>Total Price</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.data.products.map((p: any) => {
                const explodeSku = p.sku.split("-");
                let result = {};
                explodeSku.map((item: any) => {
                  const explodeItem = item.split(":");
                  if (explodeItem.length === 2) {
                    const materialItem = explodeItem[0];
                    const color = explodeItem[1];
                    const findMaterial = materials.find(
                      (f) => f.sku === materialItem
                    );
                    const findColor = colors.find((f) => f.sku === color);
                    result = {
                      ...result,
                      [findMaterial?.titleEn || "---"]:
                        findColor?.titleEn || "---"
                    };
                  }
                  return "ok";
                });

                const wMat = Object.entries(result);

                return (
                  <Tr key={p.id}>
                    <Td>
                      <img
                        src={`${p.photo}`}
                        alt="Photo"
                        title="Photo"
                        width={150}
                        height={150}
                      />
                    </Td>
                    <Td>{p.title}</Td>
                    <Td>
                      {p.barcode}
                      <br />
                      {p.sku}
                    </Td>
                    <Td>
                      {wMat.map((r, i) => {
                        if (r.length === 2) {
                          return (
                            <div key={i}>
                              {r[0]}:{r[1]}
                            </div>
                          );
                        }
                        return;
                      })}
                    </Td>
                    <Td textAlign={"center"}>{p.size}</Td>
                    <Td textAlign="center">{p.amount}</Td>
                    <Td textAlign={"right"}>{formatMoney(p.price, "USD")}</Td>
                    <Td textAlign={"right"}>
                      {formatMoney(p.price * p.amount, "USD")}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot>
              <Tr>
                <Td colSpan={6} textAlign="right">
                  Sub Total
                </Td>

                <Td textAlign={"right"}>
                  {formatMoney(data.data.totalAmount, "USD")}
                </Td>
              </Tr>

              <Tr>
                <Td colSpan={6} textAlign="right">
                  Shippping
                </Td>

                <Td textAlign={"right"}>T.B.D</Td>
              </Tr>
              <Tr>
                <Td colSpan={6} textAlign="right">
                  Grand Total
                </Td>

                <Td textAlign={"right"}>
                  {formatMoney(
                    data.data.totalAmount - data.data.discount,
                    "USD"
                  )}
                </Td>
              </Tr>
            </Tfoot>
          </Table>
        )}
        <Box height={"2px"} bgColor="#ccc" my={8}></Box>
        <Box mb={8}>
          <strong>Payment Information</strong>
          <p>Company Name: JUJU TAKI TASARIM SAN. TİC. LTD.ŞTİ</p>

          <p>USD Iban: TR80 0006 2000 1830 0009 0667 02</p>

          <p>
            Bank’s Name : T. Garanti Bankası A.Ş.
            <br />
            Branch Code :183
            <br />
            Branch Name: Valikonağı Şubesi
            <br />
            Swift code: TGBATRISXXX
            <br />
            Branch address: Teşvikiye, Vali Konağı Cd. 78/A,
            34365 Şişli/İstanbul
          </p>
        </Box>
      </Container>
    );
  }
  return (
    <Layout>
      <Container maxW="container.xl">
        An error occured. Please refresh the page
      </Container>
    </Layout>
  );
};

export default OrderDetail;
