import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { chakraTheme } from "./../styles/theme";
import client from "../lib/index";

import Dashboard from "../components/Dashboard";
import Home from "../components/Home";
import Orders from "../components/order/All";
import CreateStore from "../components/store/Create";
import CreateOrder from "../components/order/Create";
import OrderDetail from "../components/order/Detail";
import OrderPrint from "../components/order/Print";
import StoreDetail from "../components/store/Detail";
import UpdateStore from "../components/store/Update";

const AppRouter = () => {
  return (
    <ChakraProvider theme={chakraTheme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/store/create" element={<CreateStore />} />
            <Route path="/store/update/:id" element={<UpdateStore />} />
            <Route path="/store/detail/:id" element={<StoreDetail />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/order/create/:id" element={<CreateOrder />} />
            <Route path="/order/detail/:id" element={<OrderDetail />} />
            <Route path="/order/print/:id" element={<OrderPrint />} />
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default AppRouter;
