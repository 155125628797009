import { Box, Button, Flex, Input } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useMutation } from "react-query";
import formatMoney from "../../utils/formatMoney";

interface Props {
  discount: number | null;
  totalAmount: number;
  id: string;
  refetch: any;
}
const DiscountItem: React.FC<Props> = ({
  discount = 0,
  totalAmount = 0,
  id,
  refetch
}) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(discount);
  const [discountType, setDiscountType] = useState<"PERCENTAGE" | "AMOUNT">(
    "PERCENTAGE"
  );
  const { mutateAsync, isLoading } = useMutation(() =>
    axios.post(`${process.env.REACT_APP_APIURL}/eX/applyDiscount/${id}`, {
      data: { discount: discountAmount }
    })
  );

  const handleSave = async () => {
    await mutateAsync();
    await refetch();
    return setIsUpdate(false);
  };
  return (
    <Box mt={8}>
      <Flex justifyContent={"space-between"}>
        <Button
          onClick={() => setIsUpdate(!isUpdate)}
          marginRight={2}
          colorScheme={"gray"}
        >
          Apply Discount
        </Button>
      </Flex>
      {isUpdate && (
        <Box mt={4}>
          <Flex>
            <Button
              onClick={() => setDiscountType("PERCENTAGE")}
              marginRight={2}
              variant={discountType === "PERCENTAGE" ? "solid" : "outline"}
              colorScheme={"teal"}
            >
              Percentage
            </Button>
            <Button
              onClick={() => setDiscountType("AMOUNT")}
              marginRight={2}
              variant={discountType === "AMOUNT" ? "solid" : "outline"}
              colorScheme={"teal"}
            >
              Amount
            </Button>
          </Flex>

          <Box>
            {discountType === "PERCENTAGE" ? (
              <Flex mt={2}>
                <Button
                  variant={"outline"}
                  onClick={() =>
                    setDiscountAmount(Math.ceil((totalAmount * 5) / 100))
                  }
                  mr={4}
                >
                  5%
                </Button>
                <Button
                  variant={"outline"}
                  onClick={() =>
                    setDiscountAmount(Math.ceil((totalAmount * 10) / 100))
                  }
                  mr={4}
                >
                  10%
                </Button>
                <Button
                  variant={"outline"}
                  onClick={() =>
                    setDiscountAmount(Math.ceil((totalAmount * 15) / 100))
                  }
                  mr={4}
                >
                  15%
                </Button>
                <Button
                  variant={"outline"}
                  onClick={() =>
                    setDiscountAmount(Math.ceil((totalAmount * 20) / 100))
                  }
                  mr={4}
                >
                  20%
                </Button>
                <Button
                  variant={"outline"}
                  onClick={() =>
                    setDiscountAmount(Math.ceil((totalAmount * 25) / 100))
                  }
                  mr={4}
                >
                  25%
                </Button>
                <Box position={"relative"} width={"100px"}>
                  <Box position={"absolute"} right={2} top={2}>
                    %
                  </Box>
                  <Input
                    onChange={(e) =>
                      setDiscountAmount(
                        Math.ceil((totalAmount * Number(e.target.value)) / 100)
                      )
                    }
                  />
                </Box>
              </Flex>
            ) : (
              <Flex mt={2}>
                <Box position={"relative"} width={"120px"}>
                  <Box position={"absolute"} right={2} top={2}>
                    USD
                  </Box>
                  <Input
                    type={"number"}
                    step={0.1}
                    onChange={(e) => setDiscountAmount(Number(e.target.value))}
                  />
                </Box>
              </Flex>
            )}
          </Box>

          <Box>
            <Button
              mt={1}
              colorScheme={"green"}
              onClick={() => handleSave()}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Apply {discountAmount} USD Discount
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DiscountItem;
