import { Layout } from "./../Layout";
import {
  Box,
  Button,
  Container,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import formatMoney from "../../utils/formatMoney";
import { FaLongArrowAltLeft } from "react-icons/fa";
import OrderStatus from "./OrderStatus";
import { usePropertyStore } from "../../store";
import { useToast } from "@chakra-ui/react";

const OrderDetail = () => {
  const navigate = useNavigate();
  const { colors, materials } = usePropertyStore();
  const { id } = useParams();
  const toast = useToast();

  const { mutateAsync: sumupMutate, isLoading: sumupLoading } = useMutation(
    (id: string) =>
      axios.post(
        `${process.env.REACT_APP_APIURL}/eX/create-checkout-with-sumup/${id}`,
        {
          data
        }
      )
  );

  const { mutateAsync: iyzicoMutate, isLoading: iyzicoLoading } = useMutation(
    (id: string) =>
      axios.post(
        `${process.env.REACT_APP_APIURL}/eX/create-checkout-with-iyzico/${id}`,
        {
          data
        }
      )
  );

  const { mutateAsync: emailMutateAsync, isLoading: emailIsLoading } =
    useMutation((id: string) =>
      axios.post(`${process.env.REACT_APP_APIURL}/eX/send-invoice/${id}`, {
        data
      })
    );

  const handleSendEmail = async (id: string) => {
    try {
      const result = await emailMutateAsync(id);
      await refetch();
      if (result.data === "success") {
        return toast({
          title: "Success",
          description: "E-mail successfully sent",
          status: "success"
        });
      }

      return toast({
        title: "Error",
        description: "An error occured",
        status: "error"
      });
    } catch (error) {
      return toast({
        title: "Error",
        description: "An error occured",
        status: "error"
      });
    }
  };
  const { isLoading, data, refetch } = useQuery(
    "query-order-details",
    async () => {
      const url = `${process.env.REACT_APP_APIURL}/eX/order/${id}`;
      return await axios({
        method: "GET",
        url
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  if (isLoading) {
    return (
      <Box
        position={"fixed"}
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        <Spinner size={"lg"} />
      </Box>
    );
  }

  if (data) {
    return (
      <Layout>
        <Container maxW="container.xl">
          <Flex justifyContent={"space-between"} mt={6}>
            <Button
              variant="outline"
              colorScheme={"gray"}
              onClick={() => navigate(`/orders`)}
            >
              <Flex alignItems={"center"}>
                <FaLongArrowAltLeft fontSize={20} />{" "}
                <Box marginLeft={2}>Back to Orders</Box>
              </Flex>
            </Button>
            <Flex>
              <Button
                onClick={() => navigate(`/order/create/${data.data.id}`)}
                marginRight={2}
                colorScheme={"blue"}
              >
                Update
              </Button>

              <Button
                marginRight={2}
                colorScheme={"orange"}
                onClick={async () => {
                  handleSendEmail(data.data.id!);
                }}
              >
                Send Invoice
              </Button>

              <Button
                marginRight={2}
                colorScheme={"red"}
                onClick={() =>
                  window.open(
                    `/order/print/${data.data.id}`,
                    "_blank",
                    "rel=noopener noreferrer"
                  )
                }
              >
                Download Invoice
              </Button>

              {data.data.orderStatus !== "PAID" && (
                <Flex alignItems={"center"}>
                  <Button
                    isLoading={iyzicoLoading}
                    disabled={iyzicoLoading}
                    onClick={async () => {
                      await iyzicoMutate(data.data.id);
                      await refetch();
                    }}
                    marginRight={2}
                    colorScheme={"green"}
                  >
                    {data.data.iyzico
                      ? "Update Payment With Iyzico"
                      : "Get Payment With Iyzıco"}
                  </Button>
                  <Button
                    isLoading={sumupLoading}
                    disabled={sumupLoading}
                    onClick={async () => {
                      await sumupMutate(data.data.id);
                      await refetch();
                    }}
                    colorScheme={"teal"}
                  >
                    {data.data.sumUpUrl
                      ? "Update Payment With SumUp"
                      : "Get Payment With SumUp"}
                  </Button>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex
            borderBottom="1px solid"
            borderColor={"gray.200"}
            justifyContent={"space-between"}
            alignItems="center"
            marginTop={2}
            marginBottom={6}
            paddingY={2}
          >
            <Box>
              <Box fontSize={20} fontWeight="bold">
                <Link to={`/store/detail/${data.data.store.id}`}>
                  - {data.data.store.title}
                </Link>
              </Box>
              <Box mb={4}>
                {data.data.store.address} / {data.data.store.country}
              </Box>
              <Box>{data.data.store.phoneNumber}</Box>
              <Box mb={4}>{data.data.store.email}</Box>
              {data.data.sumUpUrl && (
                <Box>
                  <a
                    href={`https://juju-exhibition.cr.interaktifis.cloud/eX/pay-with-sumup?s=${data.data.sumUpUrl}`}
                    target="_blank"
                    title="Sumup"
                    rel="noreferrer"
                  >
                    Go to SumUp Url
                  </a>
                </Box>
              )}

              {data.data.iyzicoUrl && (
                <Box>
                  <a
                    href={`${data.data.iyzicoUrl}`}
                    target="_blank"
                    title="Iyzico"
                    rel="noreferrer"
                  >
                    Go to Iyzico Url
                  </a>
                </Box>
              )}
            </Box>
            <OrderStatus data={data} />
          </Flex>

          {colors && materials && (
            <Table variant={"striped"} size="lg">
              <Thead>
                <Tr>
                  <Th>Photo</Th>
                  <Th>Title</Th>
                  <Th>Barcode</Th>
                  <Th>Materials</Th>
                  <Th textAlign={"center"}>Sizes</Th>
                  <Th textAlign={"center"}>Quantity</Th>
                  <Th textAlign={"right"}>Price</Th>
                  <Th textAlign={"right"}>Total Price</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.data.products.map((p: any) => {
                  const explodeSku = p.sku.split("-");
                  let result = {};
                  explodeSku.map((item: any) => {
                    const explodeItem = item.split(":");
                    if (explodeItem.length === 2) {
                      const materialItem = explodeItem[0];
                      const color = explodeItem[1];
                      const findMaterial = materials.find(
                        (f) => f.sku === materialItem
                      );
                      const findColor = colors.find((f) => f.sku === color);
                      result = {
                        ...result,
                        [findMaterial?.title || "---"]:
                          findColor?.title || "---"
                      };
                    }
                    return "ok";
                  });

                  const wMat = Object.entries(result);

                  return (
                    <Tr key={p.id}>
                      <Td>
                        <img
                          src={`${p.photo}`}
                          alt="Photo"
                          title="Photo"
                          width={200}
                          height={200}
                        />
                      </Td>
                      <Td>
                        {p.title}
                        {p.description && (
                          <div>
                            <hr />
                            <strong>Notes</strong>
                            <br />
                            {p.description}
                          </div>
                        )}
                      </Td>
                      <Td>
                        {p.barcode}
                        <br />
                        {p.category}-{p.productGroup}
                      </Td>
                      <Td>
                        {wMat.map((r, i) => {
                          if (r.length === 2) {
                            return (
                              <div key={i}>
                                {r[0]}:{r[1]}
                              </div>
                            );
                          }
                        })}
                      </Td>
                      <Td textAlign={"center"}>{p.size}</Td>
                      <Td textAlign="center">{p.amount}</Td>
                      <Td textAlign={"right"}>{formatMoney(p.price, "USD")}</Td>
                      <Td textAlign={"right"}>
                        {formatMoney(p.price * p.amount, "USD")}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Td colSpan={6} textAlign="right">
                    Sub Total
                  </Td>

                  <Td textAlign={"right"}>
                    {formatMoney(data.data.totalAmount, "USD")}
                  </Td>
                </Tr>

                {data.data.discount > 0 && (
                  <Tr>
                    <Td colSpan={6} textAlign="right">
                      Discount
                    </Td>

                    <Td textAlign={"right"}>
                      {formatMoney(data.data.discount, "USD")}
                    </Td>
                  </Tr>
                )}

                <Tr>
                  <Td colSpan={6} textAlign="right">
                    Shipping
                  </Td>

                  <Td textAlign={"right"}>T.B.A</Td>
                </Tr>

                <Tr>
                  <Td colSpan={6} textAlign="right">
                    Grand Total
                  </Td>

                  <Td textAlign={"right"}>
                    {formatMoney(
                      data.data.totalAmount - data.data.discount,
                      "USD"
                    )}
                  </Td>
                </Tr>
              </Tfoot>
            </Table>
          )}
        </Container>
      </Layout>
    );
  }
  return (
    <Layout>
      <Container maxW="container.xl">
        An error occured. Please refresh the page
      </Container>
    </Layout>
  );
};

export default OrderDetail;
