import { Layout } from "./../Layout";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import {
  Box,
  Table,
  Th,
  Tr,
  Tbody,
  Thead,
  Container,
  Spinner,
  Flex,
  Td,
  Button
} from "@chakra-ui/react";
import PageHeading from "../ui/PageHeading";
import axios from "axios";
import { useQuery } from "react-query";
import formatMoney from "../../utils/formatMoney";

dayjs.locale("tr");
const Orders = () => {
  const navigate = useNavigate();
  const { isLoading, data } = useQuery(
    "query-get-orders",
    async () => {
      const url = `${process.env.REACT_APP_APIURL}/eX/orders`;
      return await axios({
        method: "GET",
        url
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  if (isLoading) {
    return (
      <Box
        position={"fixed"}
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        <Spinner size={"lg"} />
      </Box>
    );
  }

  if (data) {
    return (
      <Layout>
        <Container maxW="container.xl">
          <Flex
            borderBottom="1px solid"
            borderColor={"gray.200"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={6}
            paddingY={2}
          >
            <PageHeading title="Orders" />
          </Flex>
          <Table variant={"striped"} size="lg">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Store</Th>
                <Th>Employee</Th>
                <Th>Num. of Prod.</Th>
                <Th>Discount</Th>
                <Th>Total Amount</Th>
                <Th>Status</Th>
                <Th width={"150px"}>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.data.map((d: any) => {
                return (
                  <Tr key={d.id}>
                    <Td>{dayjs(d.createdAt).format("DD MMM YYYY HH:mm")}</Td>
                    <Td>{d.store.title}</Td>
                    <Td>{d.store.nameSurname}</Td>
                    <Td>{d._count.products}</Td>
                    <Td>{formatMoney(d.discount, "USD")}</Td>
                    <Td>{formatMoney(d.totalAmount, "USD")}</Td>
                    <Td>{d.orderStatus}</Td>
                    <Td>
                      <Flex>
                        <Box>
                          <Button
                            onClick={async () => {
                              return navigate(`/order/detail/${d.id}`);
                            }}
                            colorScheme="blue"
                            size={"sm"}
                          >
                            Details
                          </Button>
                        </Box>
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Container>
      </Layout>
    );
  }
  return (
    <Layout>
      <Container maxW="container.xl">
        An error occured. Please refresh the page
      </Container>
    </Layout>
  );
};

export default Orders;
