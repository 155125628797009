import * as React from "react";
import * as ReactDOM from "react-dom/client";
import AppRouter from "./router/index";
import { QueryClient, QueryClientProvider } from "react-query";
const container: any = document.getElementById("root");
const root = ReactDOM.createRoot(container);
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppRouter />
    </QueryClientProvider>
  </React.StrictMode>
);
