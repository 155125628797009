import { Layout } from "./../Layout";
import {
  Box,
  Button,
  Container,
  Flex,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
  Textarea
} from "@chakra-ui/react";
import PageHeading from "./../ui/PageHeading";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";

const CreateStore = () => {
  const [title, setTitle] = useState("");
  const [nameSurname, setNameSurname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [country, setCountry] = useState("");
  const [notes, setNotes] = useState("");

  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_APIURL}/eX/createStore`;
  const { mutateAsync, isLoading } = useMutation((data: any) =>
    axios.post(url, { data })
  );

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await mutateAsync({
        title,
        nameSurname,
        phoneNumber,
        address,
        shippingAddress,
        email,
        country,
        notes
      });
      if (result.data === "success") {
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error, "ERR");
    }
  };
  return (
    <Layout>
      <Container maxW="container.xl">
        <Flex
          borderBottom="1px solid"
          borderColor={"gray.200"}
          justifyContent={"space-between"}
          alignItems="center"
          marginY={6}
          paddingY={2}
        >
          <PageHeading title="Create New Store" />
          <Box>
            <Link to={`/dashboard`}>
              <Box
                as="span"
                bgColor={"green.500"}
                paddingX={4}
                paddingY={2}
                color="white"
              >
                Back To Stores
              </Box>
            </Link>
          </Box>
        </Flex>
        <form onSubmit={(e) => onSubmit(e)}>
          <SimpleGrid columns={2} gap={4}>
            <GridItem>
              <FormLabel>Title</FormLabel>
              <Input
                placeholder="Title"
                value={title}
                disabled={isLoading}
                onChange={(e) => setTitle(e.target.value)}
              />
            </GridItem>

            <GridItem>
              <FormLabel>Name Surname</FormLabel>
              <Input
                placeholder="Name Surname"
                value={nameSurname}
                disabled={isLoading}
                onChange={(e) => setNameSurname(e.target.value)}
              />
            </GridItem>

            <GridItem>
              <FormLabel>E-Mail</FormLabel>
              <Input
                placeholder="E-Mail"
                value={email}
                disabled={isLoading}
                onChange={(e) => setEmail(e.target.value)}
              />
            </GridItem>

            <GridItem>
              <FormLabel>Phone Number</FormLabel>
              <Input
                placeholder="Phone Number"
                value={phoneNumber}
                disabled={isLoading}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Billing Address</FormLabel>
              <Input
                placeholder="Billing Address"
                value={address}
                disabled={isLoading}
                onChange={(e) => setAddress(e.target.value)}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Shipping Address</FormLabel>
              <Input
                placeholder="Shipping Address"
                value={shippingAddress}
                disabled={isLoading}
                onChange={(e) => setShippingAddress(e.target.value)}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Notes</FormLabel>
              <Textarea
                placeholder="Notes"
                rows={4}
                disabled={isLoading}
                defaultValue={notes}
                onChange={(e) => setNotes(e.target.value)}
              ></Textarea>
            </GridItem>

            <GridItem colSpan={2}>
              <Button
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
                variant={"outline"}
                colorScheme="green"
              >
                Save
              </Button>
            </GridItem>
          </SimpleGrid>
        </form>
      </Container>
    </Layout>
  );
};

export default CreateStore;
