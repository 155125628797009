import { Layout } from "./Layout";
import {
  Box,
  Button,
  Container,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import PageHeading from "./ui/PageHeading";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { Store } from "../types/types";
import { Link, useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";

const Dashboard = () => {
  const navigate = useNavigate();

  const { mutateAsync, isLoading: mutationIsLoading } = useMutation(
    (id: string) =>
      axios.post(`${process.env.REACT_APP_APIURL}/eX/createDraftOrder/${id}`, {
        data
      })
  );

  const { isLoading, data, refetch } = useQuery(
    "query-get-stocks-by-category",
    async () => {
      const url = `${process.env.REACT_APP_APIURL}/eX/stores`;
      return await axios({
        method: "GET",
        url
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  if (isLoading) {
    return (
      <Box
        position={"fixed"}
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        <Spinner size={"lg"} />
      </Box>
    );
  }
  if (data) {
    return (
      <Layout>
        <Container maxW="container.xl">
          <Flex
            borderBottom="1px solid"
            borderColor={"gray.200"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={6}
            paddingY={2}
          >
            <PageHeading title="Exhibition Inquiries" />
            <Box>
              <Link to={`/store/create`}>
                <Box
                  as="span"
                  bgColor={"green.500"}
                  paddingX={4}
                  paddingY={2}
                  color="white"
                >
                  Create New Store
                </Box>
              </Link>
            </Box>
          </Flex>
          <Table variant={"striped"} size="lg">
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th>Employee</Th>
                <Th>Phone Number</Th>
                <Th>E-Mail</Th>
                <Th>B2B</Th>
                <Th>Orders</Th>
                <Th width="242px">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.data?.map((d: Store) => {
                return (
                  <Tr key={d.id}>
                    <Td>{d.title}</Td>
                    <Td>{d.nameSurname}</Td>
                    <Td>{d.phoneNumber}</Td>
                    <Td>{d.email}</Td>
                    <Td>{d.b2bCreated ? <FaCheckCircle /> : ""} </Td>
                    <Td>{d._count.orders}</Td>
                    <Td>
                      <Flex>
                        <Box mr={4}>
                          <Button
                            onClick={async () => {
                              return navigate(`/store/detail/${d.id}`);
                            }}
                            isLoading={mutationIsLoading}
                            disabled={mutationIsLoading}
                            colorScheme="blue"
                            size={"sm"}
                          >
                            Details
                          </Button>
                        </Box>

                        <Box mr={4}>
                          <Button
                            onClick={async () => {
                              const result = await mutateAsync(d.id);
                              return navigate(`/order/create/${result.data}`);
                            }}
                            isLoading={mutationIsLoading}
                            disabled={mutationIsLoading}
                            colorScheme="teal"
                            size={"sm"}
                          >
                            Create Order
                          </Button>
                        </Box>
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Container>
      </Layout>
    );
  }
  return (
    <Layout>
      <Container maxW="container.xl">
        An error occured. Please refresh the page
      </Container>
    </Layout>
  );
};

export default Dashboard;
