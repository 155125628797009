import { Layout } from "./../Layout";
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Spinner,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import PageHeading from "./../ui/PageHeading";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { FaBarcode, FaEdit } from "react-icons/fa";
import React, { useState, useRef } from "react";
import formatMoney from "../../utils/formatMoney";
import PlusMinus from "./PlusMinus";
import SizeItem from "./SizeItem";
import MaterialItem from "./MaterialItem";
import { usePropertyStore } from "../../store";
import PriceItem from "./PriceItem";
import NoteItem from "./NoteItem";
import DiscountItem from "./Discount";

const CreateOrder = () => {
  const ref = useRef<HTMLInputElement | null>(null);
  const { id } = useParams();
  const [barcode, setBarcode] = useState("");
  const { colors, materials } = usePropertyStore();
  const { mutateAsync, isLoading: mutationIsLoading } = useMutation(() =>
    axios.post(`${process.env.REACT_APP_APIURL}/eX/addProductToOrder/${id}`, {
      data: {
        barcode: barcode ? barcode.toUpperCase() : ""
      }
    })
  );

  const { isLoading, data, refetch } = useQuery(
    "query-order",
    async () => {
      const url = `${process.env.REACT_APP_APIURL}/eX/order/${id}`;
      return await axios({
        method: "GET",
        url
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  if (isLoading) {
    return (
      <Box
        position={"fixed"}
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        <Spinner size={"lg"} />
      </Box>
    );
  }
  const onSubmitBarcode = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await mutateAsync();
      setBarcode("");
      refetch();

      console.log(ref, "REF");
      return setTimeout(() => ref.current && ref.current.focus(), 300);
    } catch (error) {
      console.log(error);
    }
  };
  if (data) {
    return (
      <Layout>
        <Container maxW="container.xl">
          <Flex
            borderBottom="1px solid"
            borderColor={"gray.200"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={6}
            paddingY={2}
          >
            <PageHeading title={`Order for ${data.data?.store?.title}`} />
            <Flex>
              <form onSubmit={(e) => onSubmitBarcode(e)}>
                <Flex>
                  <Input
                    id="barcodeId"
                    ref={ref}
                    autoFocus
                    disabled={mutationIsLoading}
                    placeholder="Barcode"
                    value={barcode}
                    onChange={(e) => setBarcode(e.target.value)}
                  />
                  <Button
                    type="submit"
                    colorScheme={"green"}
                    disabled={mutationIsLoading}
                    isLoading={mutationIsLoading}
                  >
                    <FaBarcode />
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>
          <Table variant={"striped"} size="lg">
            <Thead>
              <Tr>
                <Th>Photo</Th>
                <Th>Title</Th>
                <Th>Barkod</Th>
                <Th>SKU</Th>
                <Th>Size</Th>
                <Th>Notes</Th>
                <Th>Quantity</Th>
                <Th>Price</Th>
                <Th>Total Price</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.data?.products.map((p: any) => {
                const explodeSku = p.sku.split("-");
                let result = {};
                explodeSku.map((item: any) => {
                  const explodeItem = item.split(":");
                  if (explodeItem.length === 2) {
                    const materialItem = explodeItem[0];
                    const color = explodeItem[1];
                    const findMaterial = materials.find(
                      (f) => f.sku === materialItem
                    );
                    const findColor = colors.find((f) => f.sku === color);
                    result = {
                      ...result,
                      [findMaterial?.title || "---"]: findColor?.title || "---"
                    };
                  }
                  return "ok";
                });

                const wMat = Object.entries(result);

                return (
                  <Tr key={p.id}>
                    <Td>
                      <img
                        src={`${p.photo}`}
                        alt="Photo"
                        title="Photo"
                        width={200}
                        height={200}
                      />
                    </Td>
                    <Td>{p.title}</Td>
                    <Td>
                      {p.barcode}
                      <br />
                      {p.category}-{p.productGroup}
                    </Td>
                    <Td>
                      {wMat.map((r, i) => {
                        if (r.length === 2) {
                          return (
                            <MaterialItem
                              key={i}
                              refetch={refetch}
                              id={p.id}
                              color={r[1] as string}
                              material={r[0]}
                            />
                          );
                        }
                        return <div>-</div>;
                      })}
                    </Td>

                    <Td>
                      <SizeItem id={p.id} size={p.size} refetch={refetch} />
                    </Td>
                    <Td>
                      <NoteItem
                        refetch={refetch}
                        id={p.id}
                        description={p.description}
                      />
                    </Td>
                    <Td>
                      <PlusMinus
                        refetch={refetch}
                        id={p.id}
                        amount={p.amount}
                      />
                    </Td>
                    <Td>
                      <PriceItem id={p.id} refetch={refetch} price={p.price} />{" "}
                    </Td>
                    <Td textAlign={"right"}>
                      {formatMoney(p.price * p.amount, p.currency)}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot>
              <Tr>
                <Td colSpan={8} textAlign="right">
                  Total:
                </Td>
                <Td textAlign={"right"}>
                  {formatMoney(data.data.totalAmount, "USD")}
                </Td>
              </Tr>
            </Tfoot>
          </Table>
          <Box mt={4} textAlign={"left"}>
            <Box
              as="span"
              backgroundColor={"green.400"}
              color="white"
              rounded="md"
              px={4}
              py={2}
            >
              <Link to={`/order/detail/${id}`}>Back to Order Detail</Link>
            </Box>
          </Box>
          <DiscountItem
            refetch={refetch}
            id={id!}
            totalAmount={data.data.totalAmount || 0}
            discount={data.data.discount}
          />
        </Container>
      </Layout>
    );
  }
  return (
    <Layout>
      <Container maxW="container.xl">
        An error occured. Please refresh the page
      </Container>
    </Layout>
  );
};

export default CreateOrder;
