import { Box, Button, Flex, Input, Textarea } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useMutation } from "react-query";
import formatMoney from "../../utils/formatMoney";

interface Props {
  description: string | null;
  id: string;
  refetch: any;
}
const NoteItem: React.FC<Props> = ({ description, id, refetch }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [newDescription, setNewDescription] = useState(description);

  const { mutateAsync, isLoading } = useMutation(() =>
    axios.post(`${process.env.REACT_APP_APIURL}/eX/updateStoreNote/${id}`, {
      data: { description: newDescription }
    })
  );
  const handleSave = async () => {
    await mutateAsync();
    await refetch();
    return setIsUpdate(false);
  };
  return (
    <Box mb={1}>
      <Flex justifyContent={"space-between"}>
        <Box>{description}</Box>
        <Button
          variant="green"
          size="xs"
          onClick={() => setIsUpdate(!isUpdate)}
        >
          <FaEdit />
        </Button>
      </Flex>
      {isUpdate && (
        <Box>
          <Textarea
            bgColor={"white"}
            onChange={(e) => setNewDescription(e.target.value)}
            defaultValue={newDescription || ""}
            rows={5}
          ></Textarea>
          <Box>
            <Button
              width={"100%"}
              mt={1}
              colorScheme={"green"}
              size="sm"
              onClick={() => handleSave()}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NoteItem;
