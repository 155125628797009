import { Button, Flex } from "@chakra-ui/react";
import axios from "axios";
import { useMutation } from "react-query";

const PlusMinus = ({
  amount,
  id,
  refetch
}: {
  amount: number;
  id: string;
  refetch: any;
}) => {
  const { mutateAsync: plusMutation, isLoading: plusIsLoading } = useMutation(
    (amount: number) =>
      axios.post(
        `${process.env.REACT_APP_APIURL}/eX/plusQuantity/${id}/${amount}`,
        {
          data: {}
        }
      )
  );

  const { mutateAsync: minusMutation, isLoading: minusIsLoading } = useMutation(
    (amount: number) =>
      axios.post(
        `${process.env.REACT_APP_APIURL}/eX/minusQuantity/${id}/${amount}`,
        {
          data: {}
        }
      )
  );

  return (
    <Flex alignItems={"center"}>
      <Button
        onClick={async () => {
          await minusMutation(amount - 1);
          await refetch();
        }}
        colorScheme={"red"}
        size={"sm"}
        isLoading={plusIsLoading || minusIsLoading}
        isDisabled={plusIsLoading || minusIsLoading}
      >
        -
      </Button>
      <Button
        bgColor="white"
        borderColor={"white"}
        variant="outline"
        disabled
        size={"sm"}
      >
        {amount}
      </Button>
      <Button
        onClick={async () => {
          await plusMutation(amount - 1);
          await refetch();
        }}
        isLoading={plusIsLoading || minusIsLoading}
        isDisabled={plusIsLoading || minusIsLoading}
        colorScheme={"blue"}
        size={"sm"}
      >
        +
      </Button>
    </Flex>
  );
};

export default PlusMinus;
