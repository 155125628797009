import React from "react";
import { Box } from "@chakra-ui/react";

interface Props {
  title?: string;
}
const PageHeading: React.FC<Props> = ({ title = "Yükleniyor" }) => {
  return (
    <Box fontSize={"2xl"} fontWeight="bold">
      {title}
    </Box>
  );
};

export default PageHeading;
