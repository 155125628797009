import { Layout } from "./../Layout";
import {
  Box,
  Button,
  Container,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast
} from "@chakra-ui/react";
import PageHeading from "./../ui/PageHeading";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import NoteItem from "./NoteItem";
import formatMoney from "../../utils/formatMoney";
import { useState } from "react";

const StoreDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showHidden, setShowHidden] = useState(false);
  const toast = useToast();

  const { mutateAsync: linesheetMutateAsync, isLoading: linesheetIsLoading } =
    useMutation((id: string) =>
      axios.post(`${process.env.REACT_APP_APIURL}/eX/send-linesheet/${id}`, {
        data
      })
    );

  const { mutateAsync: b2bMutateAsync, isLoading: b2bIsLoading } = useMutation(
    (id: string) =>
      axios.post(`${process.env.REACT_APP_APIURL}/eX/create-b2b/${id}`, {
        data
      })
  );

  const { mutateAsync: hideMutateAsync, isLoading: hideMutationIsLoading } =
    useMutation((data: { id: string; status: string }) =>
      axios.post(
        `${process.env.REACT_APP_APIURL}/eX/hideOrder/${data.id}/${data.status}`,
        {
          data
        }
      )
    );

  const { mutateAsync, isLoading: mutationIsLoading } = useMutation(
    (id: string) =>
      axios.post(`${process.env.REACT_APP_APIURL}/eX/createDraftOrder/${id}`, {
        data
      })
  );

  const { isLoading, data, refetch } = useQuery(
    "query-store-detail",
    async () => {
      const url = `${process.env.REACT_APP_APIURL}/eX/store/${id}`;
      return await axios({
        method: "GET",
        url
      });
    },
    {
      enabled: true,
      retry: 1
    }
  );

  if (isLoading) {
    return (
      <Box
        position={"fixed"}
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        <Spinner size={"lg"} />
      </Box>
    );
  }
  if (data) {
    return (
      <Layout>
        <Container maxW="container.xl">
          <Flex
            borderBottom="1px solid"
            borderColor={"gray.200"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={6}
            paddingY={2}
          >
            <PageHeading title={data.data.title} />
            <Flex>
              <Button
                mr={2}
                colorScheme={"pink"}
                onClick={() => navigate(`/store/update/${id}`)}
              >
                Update Information
              </Button>
              <Button
                mr={2}
                colorScheme={"blue"}
                onClick={async () => {
                  await linesheetMutateAsync(id!);
                  await refetch();
                  return toast({
                    title: "Success",
                    description: "Line sheet sent",
                    status: "success"
                  });
                }}
                isLoading={linesheetIsLoading}
                disabled={linesheetIsLoading}
              >
                {data.data.lineSheetSent
                  ? "Re-Send Line Sheet"
                  : "Send Line Sheet"}
              </Button>
              <Button
                mr={2}
                colorScheme={"green"}
                onClick={async () => {
                  await b2bMutateAsync(id!);
                  await refetch();
                  return toast({
                    title: "Success",
                    description: "B2B Created",
                    status: "success"
                  });
                }}
                isLoading={b2bIsLoading}
                disabled={b2bIsLoading || data.data.b2bCreated}
              >
                {data.data.b2bCreated ? data.data.b2bPassword : "Create B2B"}
              </Button>
              <Button
                onClick={async () => {
                  const result = await mutateAsync(id!);
                  return navigate(`/order/create/${result.data}`);
                }}
                isLoading={mutationIsLoading}
                disabled={mutationIsLoading}
                colorScheme={"orange"}
              >
                Create Order
              </Button>
            </Flex>
          </Flex>

          <Table variant={"striped"} size="lg">
            <Thead>
              <Tr>
                <Th>Employee</Th>
                <Th>Phone Number</Th>
                <Th>E-Mail</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{data.data.nameSurname}</Td>
                <Td>{data.data.phoneNumber}</Td>
                <Td>{data.data.email}</Td>
              </Tr>
            </Tbody>
          </Table>
          <Table variant={"striped"} size="lg" mt={4}>
            <Thead>
              <Tr>
                <Th>Billing Address</Th>
                <Th>Shipping Address</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{data.data.address}</Td>
                <Td>{data.data.shippingAddress}</Td>
              </Tr>
            </Tbody>
          </Table>
          <Table size="lg" mt={6}>
            <Thead>
              <Tr>
                <Th>Notes</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr bgColor={"white"}>
                <Td>
                  <NoteItem
                    refetch={refetch}
                    description={data.data.notes}
                    id={data.data.id}
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Flex justifyContent={"space-between"} mt={6}>
            <Box>Filter</Box>
            <Box>
              <Button size="sm" onClick={() => setShowHidden(!showHidden)}>
                {showHidden ? "Hide Hidden Orders" : "Show Hidden Orders"}
              </Button>
            </Box>
          </Flex>
          <Table variant={"striped"} size="lg" mt={6}>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th># of Products</Th>
                <Th>Discount</Th>
                <Th>Total Amount</Th>
                <Th>Status</Th>
                <Th>Payment Type</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.data.orders
                .filter((f: any) =>
                  showHidden ? 1 === 1 : f.orderStatus !== "HIDDEN"
                )
                .map((o: any) => {
                  return (
                    <Tr key={o.id}>
                      <Td>{o.createdAt}</Td>
                      <Td>{o._count.products}</Td>
                      <Td>{o.discount}</Td>
                      <Td>{formatMoney(o.totalAmount, "USD")}</Td>
                      <Td>{o.orderStatus}</Td>
                      <Td>{o.paymentType}</Td>
                      <Td>
                        <Flex>
                          <Button
                            mr={4}
                            onClick={() => navigate(`/order/detail/${o.id}`)}
                            size="sm"
                            colorScheme={"green"}
                          >
                            Details
                          </Button>

                          <Button
                            onClick={async () => {
                              await hideMutateAsync({
                                id: o.id,
                                status:
                                  o.orderStatus === "HIDDEN" ? "show" : "hide"
                              });
                              return refetch();
                            }}
                            size="sm"
                            colorScheme={"red"}
                          >
                            {o.orderStatus === "HIDDEN"
                              ? "Show Hidden"
                              : "Hide"}
                          </Button>
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Container>
      </Layout>
    );
  }
  return (
    <Layout>
      <Container maxW="container.xl">
        An error occured. Please refresh the page
      </Container>
    </Layout>
  );
};

export default StoreDetail;
